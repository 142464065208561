<template>
  <div class="home h-100 container">
    <div class="row justify-content-center h-100">
      <div>  
        
        <div class="card p-2 mb-2">
          <h2 class="mt-4 mb-4">NATIONAL HEALTH MISSION</h2>
        <div class="row logos-main">
          <div class="col-12"><img src="../assets/img/0001.jpg"></div>        
        </div>
        </div>
   
        <div class="transparent mb-2 mt-2">
          <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="routeToLogin"><span>Enroll</span></button>
        </div>
      </div>
    </div>    
    <!-- <login v-if="isNotExpired"/>
    <expired v-else/> -->
  </div>
</template>

<script>
// @ is an alias to /src'
// import Login from "../components/Login";
// import Expired from "../components/Expired";
export default {
  name: 'Home',
  components: {
    // Login,
    // Expired
  },
  data() {
    return {
      isNotExpired: true,
      pdfLink: require('../assets/doc/SNPWA Logo Final.pdf')
    }
  },
  mounted() {
    console.log(process.env)
  },
  methods: {
    routeToLogin() {
      this.$router.push({path: '/login'})
    }
  }
}
</script>
<style lang="scss" scoped>
  .logos-main img {
    height: 150px;
  }
  p {
    text-align: justify !important;
  }
  .h-100 {
    align-items: center !important;
  }
  .gx-5 {
    .col-6 {
      margin: 10px 0;
    }    
    .card {      
      height: 100%;
      background: #edeff1;
      .card-body {
        display: flex;
        justify-content: center;
        align-items: center;
        h5 {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
  .border-right {
    border-right: 1px solid #e0e0e0;
  }
  @media only screen and (min-width: 600px)  {
    .border-right-sm {
      border-right: 1px solid #e0e0e0;
    }   
  }
  ul {
  }
  ul li {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
  }
  
</style>